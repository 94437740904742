@import '../../styles/variables';

.equipment_page {
    display: flex;
    flex-direction: column;
    height: 100%;

    @mixin margins {
        margin-left: $page_margin;
        margin-right: $page_margin;

        @media screen and (max-width: $lg) {
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }

    @include margins();

    .page_title {
        font-size: 3.188rem;
        color: $fontTitle;
        font-weight: 600;
        margin-top: 7rem;

        @media screen and (max-width: $xl) {
            margin-top: 4.625rem;
            font-size: 1.875rem;
        }

        @media screen and (max-width: $md) {
            margin-top: 2.5rem;
        }
        @media screen and (max-width: $sm) {
            margin-top: 1.75rem;
        }
        &::after {
            content: '';
            border-top: 0.35rem $computed-primary solid;
            width: 8.75rem;
            margin-top: 1.625rem;
            display: block;

            @media screen and (max-width: $sm) {
                width: 4.625rem;
            }
        }
    }
    .content {
        margin-top: 2.5rem;
        margin-bottom: 3.75rem;

        @media screen and (max-width: $sm) {
            margin-bottom: 1rem;
            margin-top: 1rem;
        }

        .equipment_page_grid {
            display: grid;
            grid-template-rows: auto;
            row-gap: 1rem;
            column-gap: 1rem;
            grid-template-columns: repeat(auto-fill, 27rem);

            .pre_column {
                display: none;
            }
            @media screen and (max-width: $xl) {
                grid-template-columns: repeat(auto-fill, 25.063rem);
            }
            @media screen and (max-width: $lg) {
                grid-template-columns: repeat(auto-fill, 23rem);
            }
            @media screen and (max-width: $md) {
                grid-template-columns: repeat(auto-fill, 22rem);
            }
            @media screen and (max-width: $sm) {
                grid-template-columns: repeat(auto-fill, 20.5rem);
            }
        }
    }

    .page_navigation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        margin-top: auto;

        &.single {
            justify-content: flex-end;
        }
    }

    // .create_donation_button_container {

    // }

    .create_donation_button_container {
        .create_donation {
            padding: 1rem 1.875rem;
        }
        @media screen and (max-width: $lg) {
            z-index: 2;
            position: sticky;
            margin-left: auto;
            right: 5%;
            bottom: 1%;
        }

        @media screen and (max-width: $sm) {
            left: 5%;
            right: 5%;
            margin: auto;
            bottom: 10%;

            .create_donation {
                font-size: 0.875rem;
                padding: 1rem 1.275rem;
            }
        }

        .icon {
            fill: white;
            width: 1rem;
            height: 1rem;
            margin-left: 1rem;
            @media screen and (max-width: $sm) {
                margin-left: 0.5rem;
            }
            svg {
                width: 1rem;
                height: 1rem;
            }
        }
    }

    .p_pagination {
        margin-top: auto;
        @media screen and (max-width: $md) {
            margin-top: 1rem;
        }
    }

    .no_content_description {
        font-size: 1rem;
        color: #646464;
        margin-top: 2.125rem;
    }

    .filter {
        margin-top: 1rem;
        font-size: 0.8rem;
    }
}

.create_donation_tooltip_with_icon {
    display: flex;
    gap: 0.5rem;

    .info_icon {
        @include icon-size(1rem);
        fill: $statusOk;
        transform: translateY(0.1rem);
    }
}
