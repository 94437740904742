@import './styles/variables';

.App {
    .page {
        flex-grow: 1;
        position: relative;

        @media screen and (max-width: $lg) {
            position: unset;
        }
    }
    &.flex_column {
        display: flex;
    }
}

.initial_loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: white;
}
