@import '../../styles/variables';

.tooltip {
    position: fixed;
    background-color: white;
    width: max-content;
    max-width: 32rem;
    padding: 1rem;
    border: 1px solid $statusOk;
    transform: translate(0%, -50%);
    z-index: 10;

    @media screen and (max-width: $md) {
        max-width: 25rem;
    }

    @media screen and (max-width: $sm) {
        max-width: 20rem;
    }
}

.tooltip_arrow {
    position: fixed;
    width: 0.7rem;
    height: 0.7rem;
    background-color: white;
    border: 1px solid $statusOk;
    transform: translate(-50%, -50%) rotate(45deg);
    border-left: none;
    border-top: none;
    z-index: 11;
}
