@import '../../../styles/variables';

.product_card_button {
    .main_btn {
        font-size: 0.875rem;
        padding: auto 1.5rem;
    }
    .icon {
        width: 0.775rem;
        height: 0.775rem;
        margin-right: 0.3rem;

        &.ghost {
            svg {
                fill: $fontParagraph;
            }
        }
        svg {
            fill: white;
            width: 0.775rem;
            height: 0.775rem;
        }
    }
}
