@import '../../../styles/variables';

.beneficiary_select_dropdown {
    position: relative;
    .selection_item {
        cursor: pointer;
        position: relative;

        input {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: 3rem;
        }
        svg {
            width: 1rem;
            height: 1rem;
            fill: $computed-primary;
            transform-origin: center;
            &.right_icon {
                position: absolute;
                width: 1rem;
                height: 1rem;
                right: 4%;
                top: 36%;
                transform: rotate(90deg);
            }

            &.up_icon {
                transform: rotate(-90deg);
            }

            &.error {
                right: 2.7rem;
            }
        }
    }

    .beneficiary_select_list {
        background-color: white;
        position: absolute;
        top: 80%;
        left: 0;
        width: 100%;
        max-height: 15rem;
        border-top-left-radius: 0.2rem;
        border-top-right-radius: 0.2rem;
        border-bottom-left-radius: 2rem;
        border-bottom-right-radius: 2rem;
        border: var(--primary-color);
        overflow-y: auto;
        @include card-shadow;
        z-index: 2;
        ul {
            padding: 0 0 1rem 0;
            margin: 0;
            li {
                padding: 0.5rem 1.7rem;
                list-style-type: none;
                cursor: pointer;
                font-weight: 500;
                transition: background-color 0.5s;
                &:hover {
                    background-color: rgba($fontLightGray, 0.7);

                    transition: background-color 0.5s;
                }
            }
        }
    }

    .error_message {
        margin-left: 1.65rem;
        font-size: 0.75rem;
        color: $statusError;
        height: 0.9rem;
        transition: transform 0.2s;
        transform: translateY(-0.8rem);
    }
}
