@import '../../styles/variables';

@mixin text-wrap {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: calc(27rem - 8rem);

    @media screen and (max-width: $xl) {
        width: calc(25.064rem - 8rem);
    }

    @media screen and (max-width: $lg) {
        width: calc(23.064rem - 8rem);
    }

    @media screen and (max-width: $md) {
        width: calc(21.75rem - 8rem);
    }
    @media screen and (max-width: $sm) {
        width: 12rem;
    }
}

.product_card {
    width: 27rem;
    height: 9.875rem;
    background-color: white;
    position: relative;
    border-radius: 0.625rem;
    padding: 0.938rem;

    @media screen and (max-width: $xl) {
        width: 25.063rem;
    }

    @media screen and (max-width: $lg) {
        width: 23.063rem;
    }

    @media screen and (max-width: $md) {
        width: 21.75rem;
    }

    @media screen and (max-width: $sm) {
        width: 100%;
        padding: 1rem 0.938rem;
    }

    .information_container {
        display: flex;
        align-items: center;
        .item_icon {
            min-width: 4.875rem;
            min-height: 4.875rem;
            border: 0.063rem solid $fontLightGray;
            display: grid;
            place-items: center;
            position: relative;
            border-radius: 100%;
            z-index: 1;

            @media screen and (max-width: $sm) {
                min-width: 4rem;
                min-height: 4rem;
            }
            svg {
                z-index: 2;
            }
            &::before {
                position: absolute;
                background-color: #f5f5f5;
                width: 80%;
                height: 80%;
                content: '';
                border-radius: 100%;
                z-index: 1;
            }
        }
        .label {
            position: absolute;
            top: 0.625rem;
            right: 0.625rem;
            width: 3.938rem;
            height: 1.25rem;
            background-color: $fontParagraph;
            color: white;
            font-weight: 600;
            font-size: 0.75rem;
            display: grid;
            place-items: center;
            pointer-events: none;
            text-transform: uppercase;
            @media screen and (max-width: $md) {
                width: 3rem;
                font-size: 0.625rem;
            }
            @media screen and (max-width: $sm) {
                top: 0.4rem;
                right: 1rem;
            }
        }

        .product_description {
            display: flex;
            flex-direction: column;
            margin-left: 0.938rem;

            .family {
                text-align: left;
                font-weight: 500;
                letter-spacing: 0px;
                color: #646464;
                text-transform: uppercase;
                opacity: 1;
                font-size: 0.8rem;
            }

            .text_information {
                font-size: 1.375rem;
                font-weight: 600;
                color: $fontTitle;
                @include text-wrap;

                @media screen and (max-width: $sm) {
                    font-size: 1.313rem;
                }
            }
            .items_count {
                font-weight: 500;
                color: $fontParagraph;
                font-size: 1rem;
                transform: translateY(-0.2rem);
                @include text-wrap;

                @media screen and (max-width: $sm) {
                    font-size: 0.875rem;
                }
            }
        }
    }

    .actions_container {
        margin-top: 0.938rem;
        display: flex;
        gap: 2rem;

        @media screen and (max-width: $sm) {
            gap: 0.5rem;
        }

        .card_button {
            max-height: 2.188rem;
            font-weight: bold;
            color: white;
            @media screen and (max-width: $sm) {
            }
        }
    }
}
