@import '../../styles/variables';

.side_bar {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 5;
    transition: background-color 0.5s;

    &.open {
        pointer-events: all;
        background-color: rgba(var(--secondary-color), 0.8);
    }

    &.un_covered {
        z-index: 0;
        .side_bar_content {
            z-index: 2;
        }
    }

    .side_bar_content {
        position: absolute;
        right: 0;
        opacity: 0;
        height: 100%;
        overflow: auto;
        background-color: white;
    }

    &.hidden {
        display: none;
    }
}
