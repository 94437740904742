@import '../../styles/variables';

.status {
    height: 1.5rem;
    color: white;
    text-transform: uppercase;
    padding: 5px 0.938rem;
    font-weight: 600;
    font-size: 0.75rem;
    text-align: center;
    display: flex;
    width: fit-content;
    align-items: center;

    &.Draft {
        background-color: $fontLightGray;
    }

    &.En_attente_de_signature {
        background-color: $statusWarning;
    }
    &.En_cours {
        background-color: $statusPrepare;
    }
    &.Expédiee {
        background-color: $statusOk;
    }
    &.Terminee {
        background-color: $statusFinished;
    }
    &.Annulee {
        background-color: $statusCanceled;
    }
}
