@import '../../styles/variables';

.dashboard_page {
    @mixin page_margins {
        margin-left: $page_margin;
        margin-right: $page_margin;

        @media screen and (max-width: $md) {
            margin-left: 1.875rem;
            margin-right: 1.875rem;
        }
    }

    .main_picture {
        position: relative;
        height: 21rem;

        @media screen and (max-width: $sm) {
            height: 12.25rem;
        }

        &::after {
            top: 0;
            left: 0;
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: $computed-secondary;
            opacity: 0.7;
        }

        .base64-image {
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                position: absolute;
                object-fit: cover;
            }
        }

        .page_name {
            position: absolute;
            bottom: 1.438rem;
            left: 2.5rem;
            font-size: 5.875rem;
            font-weight: 600;
            color: white;
            z-index: 1;

            @media screen and (max-width: $md) {
                left: 1.875rem;
            }

            @media screen and (max-width: $lg) {
                font-size: 3.125rem;
            }
        }
    }

    .create_donation_btn {
        display: flex;
        justify-content: flex-end;
        transform: translateY(-55%);

        @include page_margins;

        margin-right: 3.875rem;

        &.mobile {
            margin-bottom: 1rem;
            transform: unset;
            display: flex;
            justify-content: flex-start;
            margin-right: unset;
        }

        svg {
            width: 1.875rem;
            height: 1.875rem;
            fill: white;
        }
        .create_button {
            display: flex;
            gap: 1rem;
            width: 18.25rem;
            height: 3.875rem;

            @media screen and (max-width: $sm) {
                width: 13.25rem;
                font-size: 0.875rem;
                svg {
                    width: 1.313rem;
                    height: 1.313rem;
                }
            }
        }
    }

    .donation_list_title {
        @include page_margins;

        font-size: 3.188rem;
        font-weight: bold;
        color: $fontTitle;

        @media screen and (max-width: $lg) {
            font-size: 1.875rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        @media screen and (max-width: $sm) {
            font-size: 1.5rem;
            margin-bottom: unset;
        }
    }
    .card_container {
        @include page_margins;
        margin-top: 2.125rem;
        margin-bottom: 2.125rem;

        @media screen and (max-width: $lg) {
            margin: unset;
        }

        .no_content_description {
            font-size: 1rem;
            color: $fontParagraph;
            margin-top: 2.125rem;

            @media screen and (max-width: $lg) {
                @include page_margins;
                margin-bottom: 1rem;
            }
        }

        .donation_product_list {
            grid-template-columns: repeat(auto-fill, 25.25rem);

            .donation_card {
                min-width: 21rem;
            }
        }
    }

    .list_loader {
        margin: 5rem auto 0 auto;
    }

    .donation_sidebar_content {
        width: 40.125rem;
        height: 100vh;

        @media screen and (max-width: $md) {
            width: 50vw;
        }
        @media screen and (max-width: $sm) {
            width: 100vw;
        }
    }
}
