@import '../../styles/variables';

.account_select_modal {
    width: 60rem;
    max-height: 40rem;
    min-height: 20rem;
    overflow: auto;

    @media screen and (max-height: 650px) {
        min-height: unset;
        max-height: calc(var(--doc-height) - 30px);
    }

    padding: 0rem 2rem 1rem 3rem;
    color: $fontTitle;

    .account_select_title {
        font-size: 2rem;
        font-weight: 600;
    }

    @media screen and (max-width: $lg) {
        width: 40rem;
    }

    @media screen and (max-width: $md) {
        width: 35rem;

        .account_select_title {
            font-size: 1.5rem;
        }
    }

    @media screen and (max-width: $sm) {
        width: 20rem;
        max-height: 30rem;
        .account_select_title {
            font-size: 1.2rem;
        }
    }
}
