@import '../../styles/variables';

.main_btn {
    border-radius: 1.938rem;
    border: none;
    font-weight: bold;
    color: $computed-primary-font;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0.5rem 1rem;

    &.primary {
        box-shadow: 0 0.813rem 1rem rgba(var(--primary-color), 0.4);
        background-color: $computed-primary;

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                filter: brightness(85%);
            }
        }

        svg {
            fill: $computed-primary-font;
            path {
                fill: $computed-primary-font;
            }
        }
    }

    &.ghost {
        border: 0.125rem solid $fontLightGray;
        color: $fontTitle;
        box-shadow: unset;
        background-color: white;
    }

    &.tertiary {
        border: 0.125rem solid $fontLightGray;
        background-color: white;
        color: $fontTitle;
        box-shadow: unset;

        &.active {
            border: 0.125rem solid $computed-secondary;
            background-color: $computed-secondary;
            color: $computed-primary-font;
            svg {
                fill: $computed-primary-font;
                path {
                    fill: $computed-primary-font;
                }
            }
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: $computed-secondary;
                color: $computed-primary-font;
                border: 0.125rem solid $computed-secondary;
                svg {
                    fill: $computed-primary-font;
                    path {
                        fill: $computed-primary-font;
                    }
                }
            }
        }

        svg {
            fill: $fontTitle;
            path {
                fill: $fontTitle;
            }
        }
    }

    &.disabled {
        background-color: $fontLightGray;
        box-shadow: none !important;
        cursor: default;
        color: $fontLighterGray;
        pointer-events: none;
    }
}
