@import '../../styles/variables';

.beneficiary_side_bar {
    width: 40rem;
    z-index: 2;
    padding-left: 2.063rem;
    padding-right: 2.063rem;
    word-wrap: break-word;

    @media screen and (max-width: $md) {
        width: 70vw;
    }

    @media screen and (max-width: $sm) {
        width: 100vw;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .close_icon {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .icon_container {
            width: 2.063rem;
            height: 4.063rem;
            display: grid;
            place-content: center;
            cursor: pointer;
            svg {
                width: 1.563rem;
                height: 1.563rem;
                fill: $computed-primary;
            }
        }
    }
    .beneficiary_side_bar_content {
        .title {
            font-size: 3.188rem;
            color: $fontTitle;
            font-weight: 600;
            margin-top: 4.75rem;
            @media screen and (max-width: $md) {
                font-size: 1.875rem;
            }

            &::after {
                content: ' ';
                margin-top: 1.75rem;
                border-bottom: 0.25rem solid $computed-primary;
                width: 8.75rem;
                height: 1px;
                display: block;
            }
        }
        .info {
            .sub_title {
                color: $fontTitle;
                font-size: 1.375rem;
                margin-top: 2.875rem;
                font-weight: 600;

                @media screen and (max-width: $md) {
                    font-size: 1.313rem;
                }
            }

            .information {
                margin-top: 0.688rem;
                color: $fontTitle;
                display: flex;
                flex-direction: column;
                gap: 0.1rem;
            }
        }
    }
}
