@import '../../styles/variables.scss';

.login_form {
    .form_title {
        font-size: 2.5rem;
        font-weight: 600;
        color: $fontFormTitle;

        @media screen and (max-width: $md) {
            font-size: 2rem;
        }
    }

    .form_subtitle {
        font-size: 0.8rem;
        color: $fontTitle;
        margin-top: 0.938rem;
    }

    .server_error {
        border: 1px solid $statusError;
        padding: 0.4rem 0.4rem 0.4rem 2rem;
        color: $statusError;
        font-weight: 600;
        position: relative;
        margin-bottom: 0.7rem;
        font-size: 0.85rem;

        &::before {
            content: '';
            background: url('../../assets/icons/validation-error.svg');
            background-size: cover;
            width: 1rem;
            height: 1rem;
            position: absolute;
            left: 0.6rem;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .form_container {
        margin-top: 1.938rem;
    }

    .form_reset_pass_link {
        display: block;
        font-size: 0.875rem;
        color: $fontTitle;
        text-decoration: underline;
        margin-top: 1rem;
        margin-left: 1.5rem;
        width: fit-content;
    }

    .form_session_container {
        background-color: #f5f5f5;
        height: 3rem;
        display: flex;
        align-items: center;
        border-radius: 0.875rem;
        margin-top: 1.5rem;
    }

    .form_login_btn {
        margin-top: 2.063rem;
        margin-bottom: 1rem;
        .main_btn {
            height: 3rem;
            width: 100%;
        }
    }
}
