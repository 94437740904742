@font-face {
    font-family: 'Barlow';
    src: url('../assets/fonts/Barlow-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'Barlow';
    src: url('../assets/fonts/Barlow-SemiBold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: 'Barlow';
    src: url('../assets/fonts/Barlow-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Barlow';
    src: url('../assets/fonts/Barlow-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Barlow';
    src: url('../assets/fonts/Barlow-Light.ttf');
    font-weight: 300;
}
