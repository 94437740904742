@import '../../styles/variables.scss';

.navigation_menu {
    height: 100vh;
    min-width: 20rem;
    display: flex;
    position: sticky;
    top: 0;
    left: 0;
    flex-direction: column;
    box-shadow: 0.625rem 0px 2.25rem #29474b26;
    min-height: $min-height;
    z-index: 1;
    position: sticky;
    top: 0;
    left: 0;
    .mobile_header {
        background-color: white;
        display: flex;
        justify-content: flex-end;
        gap: 5rem;

        align-items: center;
        padding-right: 2rem;
        margin-top: 1.25rem;

        .icon_placeholder {
            svg {
                width: 1.563rem;
                height: 1.563rem;
            }
        }
        svg {
            path {
                fill: $primaryMain;
            }
            line {
                stroke: $primaryMain;
            }
        }
    }

    .logo {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 12rem;
        }

        @media screen and (max-width: $lg) {
            display: none;
        }
    }
    .company_select_container {
        flex: 1.5;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .navigation_items_container {
        flex: 6;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;

        @media screen and (max-width: $lg) {
            justify-content: flex-start;
        }

        @media screen and (max-width: $md) {
            gap: 1.5rem;
        }

        .logout_container {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            margin-bottom: 2rem;
        }

        a {
            color: $fontParagraph;
            font-weight: 600;
            font-size: 1.2rem;
            text-decoration: none;
            display: flex;

            width: 100%;
            align-items: center;
            justify-content: center;
            height: 2.5rem;
            position: relative;

            .icon_container {
                width: 60%;
                display: flex;
                align-items: center;

                gap: 1rem;

                svg {
                    width: 2rem;
                    height: 2rem;

                    fill: $fontParagraph;
                }
            }

            &.active {
                color: $fontTitle;
                font-weight: bold;

                svg {
                    fill: $computed-secondary;
                }

                &::before {
                    content: '';
                    width: 0.5rem;
                    height: 140%;
                    background-color: $computed-primary;
                    position: absolute;
                    left: 0;
                    border-top-right-radius: 2rem;
                    border-bottom-right-radius: 2rem;
                }
            }

            &:hover {
                font-weight: bold;

                svg {
                    fill: $computed-secondary;
                }
            }

            &.log_out {
                cursor: pointer;
                font-size: 1rem;

                svg {
                    width: 1.2rem;
                    height: 1.2rem;

                    fill: $fontParagraph;
                }
            }
        }
    }
    .atf_logo {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-shrink: 0;
        svg {
            width: 8rem;
            margin: 1rem 0;
        }

        .pow_by {
            position: absolute;
            color: $fontTitle;
            font-size: 1rem;
            transform: translateY(-1rem);
        }
    }

    &.hidden {
        display: none;
    }
}
