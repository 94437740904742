@import '../../styles/variables';

.grid_list {
    display: grid;
    grid-template-rows: auto;
    row-gap: 1.875rem;
    column-gap: 1.875rem;
    &.no_scroll {
        overflow: hidden;
    }
    &.loading {
        grid-template-columns: unset !important;
        place-content: center;
    }

    .pre_column {
        display: none;
    }

    @media screen and (max-width: $lg) {
        overflow-y: hidden;
        overflow-x: scroll;
        display: flex;
        position: relative;
        padding: 2rem 0;

        @media (pointer: coarse) {
            @include hide-scroll-bar;
        }

        &.single {
            overflow: hidden;
        }
        .pre_column {
            display: inline;
        }
    }
}
