@import '../../styles/variables';

.app_loader {
    width: 2rem;
    height: 2rem;

    transform-origin: center;
    z-index: 1;

    &.is_absolute {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &.is_dark {
        path {
            fill: $fontTitle;
        }
    }

    path {
        fill: white;
    }

    svg {
        animation: circle-animation 1s infinite linear;
    }

    @keyframes circle-animation {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
}
