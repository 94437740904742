@import url(./styles/fonts.scss);
@import './styles/variables.scss';

html {
    @media screen and ($landscape-pc-media-xxl) {
        font-size: 13px;
    }

    @media screen and ($landscape-pc-media) {
        font-size: 11px;
    }
}
body {
    margin: 0;
    font-family: 'Barlow', '-apple-system', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
    box-sizing: border-box;
}
