@import '../../styles/variables.scss';

.login_page {
    $mobilePending: 0rem 1.625rem;
    $tabletPending: 0rem 2.75rem;
    $tabletLandScapePending: 0rem 3.25rem;
    $desktopPending: 0 10%;

    display: grid;
    height: 100vh;
    min-height: $min-height;

    grid-template-columns: 4fr 2fr;
    grid-template-rows: 2fr 2fr 1fr;

    @media screen and (max-width: $xl) {
        grid-template-columns: 4fr 2.5fr;
    }

    @media screen and (max-width: $lg) {
        grid-template-columns: 1fr 1.2fr;
    }

    @media screen and ($landscape-pc-media) {
        grid-template-columns: 3fr 1fr;
    }

    @media screen and (max-width: $sm) {
        grid-template-columns: 100%;
        grid-template-rows: 1fr 1.5fr 4fr 3fr;
    }

    .outside_content {
        grid-column: 1/2;
        grid-row: 1/4;
        background-image: url('../../assets/images/log-in.jpeg');
        background-size: cover;
        position: relative;
        color: white;
        white-space: pre-line;

        @media screen and (max-width: $sm) {
            grid-column: unset;
            grid-row: 2/3;
            padding: 3rem 0;
            background-image: url('../../assets/images/pencil-img.jpeg');
        }
    }

    .outside_content::before {
        content: '';
        background-color: rgba(15, 42, 86, 0.73);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .logo {
        grid-column: 2/3;
        grid-row: 1/2;
        padding: $desktopPending;
        display: flex;
        align-items: center;
        transform: translateY(2rem);

        svg {
            width: 13rem;
        }

        @media screen and (max-width: $lg) {
            min-height: 10rem;
        }

        @media screen and (max-width: $sm) {
            transform: unset;
            min-height: unset;
            grid-column: unset;
            grid-row: 1/2;
            width: 10rem;
            height: $mobile_header;
            padding: $mobilePending;
        }
    }

    .form {
        grid-column: 2/3;
        grid-row: 2/3;
        padding: $desktopPending;

        @media screen and (max-width: $sm) {
            grid-column: 1/2;
            grid-row: 3/4;
            padding: $mobilePending;
            margin-top: 0;
            padding-top: 1.858rem;
        }
    }

    .legal_links_container {
        grid-column: 2/3;
        grid-row: 3/4;
        margin-top: 4rem;
        padding: $desktopPending;

        @media screen and (max-width: $sm) {
            grid-row: 4/5;
            grid-column: 1/2;
            padding: $mobilePending;
            margin-top: 0;
            padding-top: 2.5rem;
        }
    }

    .additional_info_container {
        z-index: 2;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        padding: 0 1.813rem;

        .hello_text {
            font-size: 3rem;
            z-index: 2;
            margin-bottom: 6.156rem;
            font-weight: 600;

            @media screen and (max-width: $xl) {
                font-size: 2.5rem;
            }

            @media screen and (max-width: $lg) {
                font-size: 1.8rem;
            }

            @media screen and (max-width: $sm) {
                font-size: 1.2rem;
                margin-bottom: unset;
            }
        }
    }

    .main_page_legal_links {
        @media screen and (max-width: $sm) {
            padding-top: 3rem;
        }
    }
}
