@import '../../../styles/variables';
.donation_overview {
    width: 40rem;
    z-index: 2;
    padding: 0rem 2rem 1rem 2rem;

    @media screen and (max-width: $md) {
        width: 28.688rem;
    }

    @media screen and (max-width: $sm) {
        width: 100vw;
        padding: 0rem 1rem 1rem 1rem;
    }

    .close_icon {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .icon_container {
            width: 2.063rem;
            height: 4.063rem;
            display: grid;
            place-content: center;
            cursor: pointer;
            svg {
                width: 1.563rem;
                height: 1.563rem;
                fill: $computed-primary;
            }
        }
    }
    .donation_meta_data {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 2.75rem;

        .donation_id {
            text-transform: uppercase;
            color: $fontParagraph;
            font-size: 0.8rem;
            color: $fontParagraph;
        }
    }

    .donation_sidebar_title {
        font-size: 3.188rem;
        color: $fontTitle;
        font-weight: 600;
        margin-top: 1rem;
        word-wrap: break-word;

        @media screen and (max-width: $xl) {
            margin-top: 1rem;
        }

        @media screen and (max-width: $md) {
            font-size: 2.188rem;
        }

        @media screen and (max-width: $sm) {
            font-size: 1.875rem;
        }

        &::after {
            content: ' ';
            margin-top: 2.5rem;
            border-bottom: 0.25rem solid $computed-primary;
            width: 8.75rem;
            height: 1px;
            display: block;
            position: absolute;
            @media screen and (max-width: $md) {
                margin-top: 2rem;
            }
        }
    }

    .donation_equipment_list {
        margin-top: 2rem;
    }

    .sidebar_beneficiary_select_container {
        .sidebar_beneficiary_section_name {
            display: flex;
            align-items: center;
            gap: 1rem;
            font-size: 1.375rem;
            color: $fontTitle;
            font-weight: 600;

            .beneficiary_icon {
                width: 2.375rem;
                height: 2.375rem;
                svg {
                    width: 2.375rem;
                    height: 2.375rem;
                    fill: $computed-primary;
                }
            }
        }

        .beneficiary_short_data {
            color: $fontTitle;
            margin-left: 0rem;
            .info_row {
                margin: 0.3rem 0;
            }
        }

        &.client {
            margin-top: 2rem;
        }
    }

    .donation_options {
        margin-bottom: 2rem;
        .option {
            padding: 0.5rem 0;
            color: $fontTitle;
            font-weight: bold;
            border-bottom: 0.063rem solid $fontLightGray;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1.175rem;
            flex-direction: row;

            .total {
                font-size: 1rem;
                min-width: 4rem;
                text-align: end;
            }
        }
    }

    .price_container {
        margin-top: 2rem;
        .price_row {
            padding: 0.5rem 0;
            color: $fontTitle;
            font-weight: bold;

            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1rem;
            flex-direction: row;
        }

        .underline {
            border-bottom: 0.063rem solid $fontLightGray;
        }
    }

    .date {
        margin-top: 0.5rem;
        font-weight: 400;
        color: $fontTitle;
        font-size: 0.8rem;
    }
}
