@import '../../styles/variables';

.text_input {
    display: flex;
    flex-direction: column;
    flex-direction: column-reverse;
    overflow: hidden;
    .label_input_container {
        position: relative;
        display: flex;
        margin-top: 0.5rem;
        .show_pass {
            position: absolute;
            right: 2%;
            top: 50%;
            width: 1rem;
            height: 1rem;
            transform: translate(-50%, -50%);
            border-radius: 100%;
            display: flex;

            opacity: 0.3;
            cursor: pointer;

            &.active {
                opacity: 1;
            }
        }

        input {
            outline: none;
            border-radius: 4.375rem;
            height: 3rem;
            color: $fontTitle;
            border: 0.125rem solid $fontLightGray;
            padding-left: 1.58rem;
            width: 100%;
            font-size: 1rem;

            &:autofill {
                box-shadow: 0 0 0 5rem white inset;
            }

            &:focus + label {
                font-size: 0.75rem;
                top: 0%;
                background-color: white;
                padding: 0.01rem 0.2rem;
                transition: top 0.5s, font-size 0.5s;
            }

            &:-webkit-autofill + label {
                font-size: 0.75rem;
                top: 0%;
                background-color: white;
                padding: 0.01rem 0.2rem;
                transition: top 0.5s, font-size 0.5s;
            }
        }

        label {
            position: absolute;
            font-size: 1rem;
            top: 50%;
            transform: translateY(-50%);
            left: 1.5rem;
            transition: top 0.5s, font-size 0.5s;
            pointer-events: none;
        }

        .label--static {
            font-size: 0.75rem;
            top: 0%;
            background-color: white;
            padding: 0.01rem 0.2rem;
            transition: top 0.5s, font-size 0.5s;
        }
    }

    .error_message {
        margin-left: 1.65rem;
        font-size: 0.75rem;
        color: $statusError;
        height: 0.9rem;
        transition: transform 0.2s;
        transform: translateY(2rem);
    }

    &.input--success {
        :is(input, label) {
            border-color: $primaryMain;
            color: $primaryMain;
        }

        .error_message {
            transform: translateY(2rem);
        }
    }

    &.input--error {
        .error_message {
            transform: translateY(0rem);
        }
        .show_pass {
            right: 10%;
            transition: right 0.2s cubic-bezier(0.42, 0, 0.1, 1.56);
        }
        .label_input_container::after {
            content: '';
            background: url('../../assets/icons/validation-error.svg');
            background-size: cover;
            width: 1rem;
            height: 1rem;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 100%;
            animation: error-icon-animation 0.2s cubic-bezier(0.42, 0, 0.1, 1.56);
            animation-fill-mode: forwards;

            @keyframes error-icon-animation {
                from {
                    right: -10%;
                }
                to {
                    right: 2%;
                }
            }
        }

        :is(input, label) {
            border-color: $statusError;
            color: $statusError;
        }
    }
}
