@import '../../styles/variables';

.input_group {
    display: flex;
    gap: 0.438rem;
    cursor: pointer;
    margin: 0.5rem;
    align-items: center;
}

.label {
    color: $fontTitle;
    font-weight: 600;
    cursor: pointer;
}

.box {
    width: 1.25rem;
    height: 1.25rem;
    border: 0.125rem solid $fontLightGray;
    position: relative;
    border-radius: 0.25rem;
}

.box.checked::after {
    content: '';
    position: absolute;
    background-color: #99b833;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0.063rem;
    animation: check-box-select-animation 0.2s ease-in forwards;
}

.circle {
    width: 1.25rem;
    height: 1.25rem;
    border: 0.125rem solid $fontLightGray;
    position: relative;
    border-radius: 100%;
}

.circle.checked::after {
    content: '';
    position: absolute;
    background-color: #99b833;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    animation: check-box-select-animation 0.2s ease-in forwards;
}

@keyframes check-box-select-animation {
    from {
        width: 0%;
        height: 0%;
    }

    to {
        width: 50%;
        height: 50%;
    }
}
