@import '../../styles/variables';

@mixin text-wrap {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;

    // @media screen and (max-width: $md) {
    //     max-width: 17rem;
    // }

    // @media screen and (max-width: $sm) {
    //     max-width: 15rem;
    // }
}

.donation_card {
    cursor: pointer;

    min-height: 14.813rem;
    background-color: white;
    padding: 1.5rem;
    border-radius: 0.625rem;
    transition: box-shadow 1s;

    &:where(:hover) {
        box-shadow: 0px 0px 36px #29474b26;
    }

    .status {
        height: 1.5rem;
        color: white;
        text-transform: uppercase;
        padding: 5px 0.938rem;
        font-weight: 600;
        font-size: 0.75rem;

        &.Draft {
            background-color: $fontLightGray;
        }

        &.En_attente_de_signature {
            background-color: $statusWarning;
        }
        &.En_cours {
            background-color: $statusPrepare;
        }
        &.Expédiee {
            background-color: $statusOk;
        }
        &.Terminee {
            background-color: $statusFinished;
        }
    }
    .additional_data {
        display: flex;
        justify-content: space-between;

        .check_email {
            color: $statusError;
            margin-top: 1.438rem;
        }

        .ref {
            color: $fontParagraph;
            font-size: 0.875rem;
            font-weight: 500;
            margin-top: 1.438rem;
        }
        .date {
            color: $fontParagraph;
            font-size: 0.875rem;
            margin-top: 0.4rem;
            font-weight: 400;
        }
    }

    .title {
        color: $fontTitle;
        font-size: 1.938rem;
        font-weight: 600;
        transform: translateX(-0.5%);
        @include text-wrap;
        @media screen and (max-width: $sm) {
            font-size: 1.313rem;
        }
    }

    .sub_title {
        margin-top: 0.313rem;
        color: $fontTitle;
        font-weight: 500;
    }

    .action {
        margin-top: 1.438rem;
        color: $fontTitle;
        display: flex;
        align-items: baseline;
        gap: 0.2rem;
        font-weight: 500;
        width: fit-content;

        svg {
            width: 0.6rem;
            height: 0.6rem;
            transform: rotate(-90deg);
            fill: $computed-primary;
            stroke: $computed-primary;
            transition: transform 0.5s;
            stroke-width: 0.1rem;
        }

        &:hover {
            text-decoration: underline;
            svg {
                transform: rotate(-90deg) translateY(20%);
            }
        }
    }
}
