@import '../../styles/variables';

@mixin text-wrap {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 19rem;

    @media screen and (max-width: $md) {
        max-width: 17rem;
    }

    @media screen and (max-width: $sm) {
        max-width: 15rem;
    }
}

.beneficiarie_card {
    min-width: 21rem;
    height: 13.625rem;
    padding: 2.5rem 1.5rem;
    background-color: white;
    border-radius: 0.625rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:hover {
        @include card-shadow;
    }

    @media screen and (max-width: $md) {
        min-width: 19rem;
        padding: 2rem 1.5rem;
    }

    .title {
        font-size: 1.375rem;
        font-weight: 600;
        color: $fontTitle;
        @include text-wrap;
    }

    .address_container {
        font-size: 1rem;
        font-weight: 400;
        color: $fontTitle;
        div {
            @include text-wrap;
        }
    }
    .actions {
        display: flex;
        gap: 1.25rem;
        color: $fontParagraph;
        font-weight: 500;

        .action_item {
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
