@import '../../styles/variables';

.page_filter {
    color: $fontTitle;
    display: flex;
    gap: 1.25rem;
    flex-wrap: wrap;
    .filter_item {
        opacity: 0.5;
        cursor: pointer;
        text-transform: uppercase;
        &.active {
            opacity: 1;
            font-weight: 600;
        }
    }
}
