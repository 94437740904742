@import '../../styles/variables';

$tablet_page_margin: 1.875rem;
$mobile_page_margin: 1rem;
@mixin margins {
    margin-left: $page_margin;
    margin-right: $page_margin;

    @media screen and (max-width: $lg) {
        margin-left: $tablet_page_margin;
        margin-right: $tablet_page_margin;
    }
    @media screen and (max-width: $md) {
        margin-left: $mobile_page_margin;
        margin-right: $mobile_page_margin;
    }
}

.donation_page {
    $tablet_page_margin: 1.875rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    @include margins;

    .page_title {
        font-size: 3.188rem;
        color: $fontTitle;
        font-weight: 600;
        display: grid;
        margin-top: 7rem;
        margin-bottom: 2rem;

        @media screen and (max-width: $lg) {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        &::after {
            border-top: 0.35rem $computed-primary solid;
            width: 8.75rem;
            margin-top: 1.625rem;
            content: '';
        }
    }

    .donation_grid_list {
        display: grid;
        grid-template-rows: auto;
        row-gap: 1rem;
        column-gap: 1rem;
        grid-template-columns: repeat(auto-fill, 21rem);
        margin-bottom: 3.7rem;

        @media screen and (max-width: $xl) {
            grid-template-columns: repeat(auto-fill, 21rem);
        }
        @media screen and (max-width: $lg) {
            grid-template-columns: repeat(auto-fill, 21rem);
        }
        @media screen and (max-width: $md) {
            grid-template-columns: repeat(auto-fill, 20rem);
        }

        .pre_column {
            display: none;
        }
    }

    .donation_list_pagination {
        margin-top: auto;
        margin-bottom: 1rem;
    }
}
