.validation_sidebar {
    .donation_sidebar_title {
        &::after {
            margin-top: 1rem;
        }
    }
    .message {
        margin: 2.375rem 0rem;
        padding-right: 3rem;
    }
    .actions {
        display: flex;
        gap: 1rem;

        .btn {
            padding: 1rem 2.5rem;
        }

        .dashboard {
            text-transform: capitalize;
        }
    }
}
