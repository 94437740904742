@import '../../styles/variables';

.legal_links {
    height: 3.25rem;
    display: flex;
    justify-content: space-between;
    border-top: 0.125rem solid rgba(255, 255, 255, 0.24);
    align-items: center;
    font-size: 0.85rem;

    @media screen and (max-width: $lg) {
        height: inherit;
        margin-bottom: inherit;
        display: flex;
        flex-direction: column-reverse;
        align-items: unset;

        a {
            color: black;
        }
    }

    .copyright {
        @media screen and (max-width: $lg) {
            margin: 1rem 0;
        }
        @media screen and (max-width: $sm) {
            text-align: center;
        }
    }

    .links_list {
        display: flex;
        gap: 1.938rem;

        @media screen and (max-width: $lg) {
            border-top: 0.125rem solid $fontLightGray;
            border-bottom: 0.125rem solid $fontLightGray;
            padding: 1rem 0;
            flex-direction: column;
            gap: 0.875rem;
        }

        @media screen and (max-width: $sm) {
            text-align: center;
        }
    }
}

.legal_links_page {
    height: 4rem;
    border-top: 0.125rem solid $fontLightGray;
    font-size: 0.85rem;
    align-items: center;
    flex-direction: row;

    @media screen and (max-width: $lg) {
        flex-direction: row;
    }

    @media screen and (max-width: $sm) {
        height: inherit;
        margin-bottom: inherit;
        display: flex;
        flex-direction: column-reverse;
        align-items: unset;

        a {
            color: black;
        }
    }

    .copyright {
        @media screen and (max-width: $sm) {
            margin: 1rem 0;
        }
    }

    .links_list {
        @media screen and (max-width: $lg) {
            flex-direction: row;
            border-bottom: none;
            border-top: none;
        }
        @media screen and (max-width: $sm) {
            border-bottom: 0.125rem solid $fontLightGray;
            padding: 1rem 0;
            flex-direction: column;
            gap: 0.875rem;
            text-align: center;
        }
    }
}
