@import '../../styles/variables';

.counter_button {
    border: 0.125rem solid $fontLightGray;
    border-radius: 1.875rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 5.688rem;
    height: 2.188rem;

    &.error {
        border-color: $statusError;
    }

    &.not_editable {
        .action {
            visibility: hidden;
        }
    }

    .action {
        flex: 1;
        color: $fontTitle;
        font-weight: 600;
        opacity: 0.6;
        cursor: pointer;
        height: 100%;
        display: grid;
        place-items: center;

        // &:hover {
        //     background-color: $secondaryMain;
        //     color: white;
        // }

        // &.left {
        //     border-top-left-radius: 1.875rem;
        //     border-bottom-left-radius: 1.875rem;
        // }
        &.right .max_item {
            font-size: 0.7rem;
            transform: translateX(-0.2rem);
        }

        div {
            transform: translateY(-0.1rem);
            display: grid;
            place-items: center;
        }
    }

    input {
        flex: 1;
        width: 1.875rem;
        outline: none;
        border: none;
        height: 100%;
        text-align: center;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
}
