@import '../../styles/variables';

.page_layout_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f5f5f5;

    @media screen and (max-width: $lg) {
        justify-content: space-between;
        min-height: calc(var(--doc-height) - $mobile_header);
    }

    @media screen and (max-width: $sm) {
        padding: unset;
    }

    .help_me_btn {
        align-self: flex-end;
        display: flex;
        position: absolute;
        z-index: 1;
        font-weight: 600;
        margin: 2rem 4rem 0rem 0rem;

        .contact_link {
            color: $fontTitle;
            opacity: 0.5;
        }

        &.dashboard_help {
            .contact_link {
                color: white;
                opacity: 1;
            }
        }
    }

    .page_content {
        height: 100%;
    }

    .legal_links_containers {
        color: $fontTitle;
        margin: auto 2rem 0rem 2rem;
        @media screen and (max-width: $lg) {
            margin: auto 1rem 0rem 1rem;
        }

        @media screen and (max-width: $sm) {
            margin: auto 1rem 0rem 1rem;
        }
        a {
            color: $fontTitle;
        }
    }
}
