@import '../../styles/variables';

.pagination {
    display: flex;
    align-items: center;
    gap: 1rem;

    @media screen and (max-width: $sm) {
        gap: 0.4rem;
    }
    .pagination_items_container {
        display: flex;
        gap: 0.625rem;

        @media screen and (max-width: $sm) {
            gap: 0.1rem;
        }
        .pagination_item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 100%;
            border: 0.125rem solid $fontLightGray;
            cursor: pointer;

            @media screen and (max-width: $sm) {
                width: 1.8rem;
                height: 1.8rem;
                border: 0.1rem solid $fontLightGray;
                font-size: 0.8rem;
            }

            &:hover {
                border-color: $computed-secondary;
            }
            &.active {
                background-color: $computed-secondary;
                border: 0.125rem solid $computed-secondary;
                color: $computed-secondary-font;
            }
        }
        .separator {
            align-self: center;
        }
    }

    .action {
        color: $fontParagraph;
        font-weight: 500;
        cursor: pointer;

        @media screen and (max-width: $sm) {
            font-size: 0.8rem;
        }
        &.next {
            color: $fontTitle;
        }
    }
}
