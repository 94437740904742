@import '../../styles/variables';

.beneficiaries_page {
    $tablet_page_margin: 1.875rem;
    display: flex;
    flex-direction: column;
    height: 100%;

    @mixin margins {
        margin-left: $page_margin;
        margin-right: $page_margin;

        @media screen and (max-width: $lg) {
            margin-left: $tablet_page_margin;
            margin-right: $tablet_page_margin;
        }
    }

    .beneficiaries_title_container {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-right: 4rem;

        @media screen and (max-width: $md) {
            flex-direction: column;
            gap: 1rem;
        }
        .page_title {
            @include margins();

            font-size: 3.188rem;
            color: $fontTitle;
            font-weight: 600;
            display: grid;
            margin-top: 7rem;

            @media screen and (max-width: $lg) {
                margin-top: 1rem;
            }
            &::after {
                border-top: 0.35rem $computed-primary solid;
                width: 8.75rem;
                margin-top: 1.625rem;
                content: '';
            }
        }

        .create_benef_link {
            color: $fontTitle;
            opacity: 0.5;

            &:hover {
                color: #454545;
                opacity: 1;
            }

            &:active {
                opacity: 1;
                color: black;
            }
        }
    }

    .create_beneficiaries {
        font-size: 1rem;
        color: $fontTitle;
        opacity: 0.5;
        font-weight: 500;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        @media screen and (max-width: $sm) {
            opacity: 1;
        }
    }

    .create_ben_btn {
        margin-bottom: 1rem;
        color: white;
        padding: 1rem;

        @include margins();

        @media screen and (max-width: $md) {
            width: 40%;
        }
        @media screen and (max-width: $sm) {
            width: unset;
        }
    }

    .filters {
        margin: 2rem 0;
        @include margins();
    }

    .cards_container {
        @include margins();
        margin-bottom: 1rem;
        margin-top: 1rem;
        .list_loader {
            margin: 5rem auto 0 auto;
        }

        .beneficiary_grid_list {
            grid-template-columns: repeat(auto-fill, 21.25rem);
        }
    }

    .list_separator {
        font-weight: 600;
        font-size: 1.375rem;
        color: $fontTitle;

        margin-top: 1.875rem;
        margin-bottom: 1rem;

        @media screen and (max-width: $lg) {
            margin-top: unset;
            margin-bottom: unset;
        }
    }

    .b_pagination {
        margin-top: auto;
        @include margins();
        margin-bottom: 1rem;
        @media screen and (max-width: $md) {
            margin-top: 1rem;
        }
    }

    .no_content_description {
        @include margins();
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}
