$page_margin: 2rem;

$xxxl: 2300px;
$xxl: 1400px;
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 579px;

$min-height: 650px;
$min-height-xxl: 950px;
$landscape-pc-media: '(max-width: #{$xxxl}) and (min-width: #{$lg}) and (max-height: #{$min-height})';
$landscape-pc-media-xxl: '(max-width: #{$xxxl}) and (min-width: #{$lg}) and (max-height: #{$min-height-xxl})';

$mobile_header: 5rem;

$primaryMain: #7fa600;
$primaryHover: #597404;
$primaryLight: #a6cf1e;

$secondaryMain: #007d8f;
$secondaryHover: #095661;
$secondaryLight: #3aa3b3;

$statusWarning: #f38a12;
$statusOk: #5eab41;
$statusError: #ff1721;
$statusPrepare: #0e2d5e;
$statusInactive: #d0d0d0;
$statusGrade: #646464;
$statusFinished: #c55555;
$statusCanceled: #440105;

$fontTitle: #454545;
$fontParagraph: #646464;
$fontLightGray: #d0d0d0;
$fontLighterGray: #f5f5f5;

$fontFormTitle: #0f2a56;

:root {
    --primary-color: 0, 128, 0;
    --secondary-color: 0, 0, 160;
    --primary-color-text: 255, 255, 255;
    --secondary-color-text: 255, 255, 255;
}

$computed-primary-font: rgb(var(--primary-color-text));
$computed-secondary-font: rgb(var(--secondary-color-text));
$computed-primary: rgb(var(--primary-color));
$computed-secondary: rgb(var(--secondary-color));

@mixin flex_column_center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin flex_row_center {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@mixin hide-scroll-bar {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }
}

@mixin card-shadow {
    box-shadow: 0px 0px 36px #29474b26;
}

@mixin icon-size($size) {
    width: $size;
    height: $size;

    svg {
        width: $size;
        height: $size;
    }
}
