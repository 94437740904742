@import '../../styles/variables';

.need_help {
    cursor: pointer;
    &_content {
        display: flex;
        gap: 0.4rem;
        align-items: center;

        .contact_link {
            color: $fontTitle;
            opacity: 0.5;
            font-weight: 600;
        }
        svg {
            fill: $computed-primary;
            stroke: $computed-primary;
            width: 1rem;
            height: 1rem;
        }
    }

    &_modal_content {
        padding: 2rem 2rem 5rem 2rem;
        max-height: 40rem;
        min-height: 20rem;
        max-width: 60rem;
        width: 30rem;
        color: $fontTitle;

        @media screen and (max-width: $sm) {
            max-width: none;
            width: 100%;
        }

        .title {
            font-size: 2.5rem;
            font-weight: 600;

            @media screen and (max-width: $sm) {
                font-size: 2rem;
            }

            .underline {
                content: '';
                border-bottom: 0.2rem $computed-primary solid;
                width: 7rem;
                height: 0.01rem;
                display: block;
                margin: 1rem 0;
                color: $computed-primary;
                @media screen and (max-width: $sm) {
                    width: 6rem;
                }
            }
        }

        .sub_title {
            font-size: 1.5rem;
            font-weight: 600;

            @media screen and (max-width: $sm) {
                font-size: 1rem;
            }
        }

        .help_description {
            margin-top: 1rem;
            opacity: 0.8;

            @media screen and (max-width: $sm) {
                font-size: 0.9rem;
            }
        }

        .contact_container {
            margin-top: 1rem;
            display: flex;
            align-items: baseline;
            flex-wrap: wrap;

            @media screen and (max-width: $sm) {
                font-size: 0.9rem;
            }

            .phone,
            .email {
                color: $computed-primary;
                margin: 0 0.5rem;
                font-weight: 600;
            }
            .phone {
                font-size: 1.3rem;
                text-decoration: none;

                @media screen and (max-width: $sm) {
                    font-size: 1rem;
                }
            }
        }
    }
}
