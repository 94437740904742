@import '../../styles/variables';

.app-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--secondary-color), 0.8);
    top: 0;
    left: 0;
    min-height: $min-height;
    z-index: 5;

    @media screen and (max-height: 650px) {
        align-items: flex-start;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    .content_container {
        background-color: white;
        display: flex;
        flex-direction: column;
        border-radius: 1rem;

        .close_button {
            align-self: flex-end;
            cursor: pointer;
            margin-right: 1rem;
            margin-top: 1rem;
            svg {
                fill: $computed-primary;
                width: 1rem;
                height: 1rem;
            }
        }
    }
}
