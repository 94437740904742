@import '../../styles/variables';

.snack_bar {
    position: fixed;
    width: 50%;
    height: 5rem;
    box-shadow: 0px 9px 24px #00000059;
    //border-bottom: solid 0.2rem $statusOk;
    background-color: white;
    display: flex;
    align-items: center;
    left: 50%;
    bottom: 0%;
    transform: translate(-50%, -50%);
    z-index: 10;

    @media screen and (max-width: $lg) {
        width: 80%;
    }
    @media screen and (max-width: $sm) {
        font-size: 0.8rem;
        width: 90%;
    }

    &.error {
        justify-content: space-between;
        padding-right: 2.563rem;
        top: 10%;
        height: 6.688rem;

        @media screen and (max-width: $lg) {
            bottom: 0;
            top: unset;
            height: 5.25rem;
        }

        .close_error {
            font-size: 0.8rem;
            display: flex;
            gap: 0.625rem;
            align-items: center;
            padding: 0.625rem 1.563rem;

            @media screen and (max-width: $lg) {
                border: none;
                position: absolute;
                top: 10%;
                right: 0;

                .close_text {
                    display: none;
                }
            }
            svg {
                fill: $fontTitle;

                @media screen and (max-width: $lg) {
                    width: 0.6rem;
                    height: 0.6rem;
                }
            }
        }
        .content {
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            .icon {
                @include flex_row_center;
                gap: 0.5rem;
                color: $statusError;
                svg {
                    width: 1rem;
                    height: 1rem;
                    path {
                        fill: $statusError;
                    }
                }
            }

            .description {
                margin-left: 1.5rem;
                font-weight: normal;
                color: $fontParagraph;
            }
        }

        .loader {
            background-color: $statusError;
        }
    }

    .content {
        margin-left: 2rem;
        color: $statusOk;
        font-weight: bold;
        display: flex;
        align-items: center;
        gap: 0.7rem;

        .icon {
            height: 1rem;
        }

        svg {
            width: 1rem;
            height: 1rem;
            path {
                fill: $statusOk;
            }
        }
    }

    .loader {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.3rem;
        background-color: $statusOk;
    }
}
