@import '../../../styles/variables';

.account_select {
    height: 3rem;
    width: 13rem;
    border: 2px solid $fontLightGray;
    border-radius: 4.3rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 1rem 0;
    position: relative;
    cursor: pointer;
    &.non_interactive {
        text-align: center;
        pointer-events: none;
    }

    .account_name {
        margin-left: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: calc(100% - 3rem);
        font-weight: 600;
    }

    .drop_down_arrow {
        position: absolute;
        right: 0.5rem;
        top: 50%;
        display: flex;
        height: fit-content;
        transform: translate(-50%, -50%);
        svg {
            fill: $computed-primary;
            width: 1rem;
            height: 1rem;
        }
    }
}
