@import '../../../styles/variables';

@mixin text-wrap {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 21rem;

    @media screen and (max-width: $xl) {
        width: 21rem;
    }

    @media screen and (max-width: $lg) {
        width: 21rem;
    }

    @media screen and (max-width: $md) {
        width: 12rem;
    }
    @media screen and (max-width: $sm) {
        width: 10rem;
        margin-right: 0.5rem;
    }
}

.sidebar_equipment_card {
    border-bottom: 0.063rem solid $fontLightGray;
    padding: 1.2rem 0;

    .card_equipment_data {
        margin-bottom: 0.6rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .card_information_container {
            display: flex;
            align-items: center;

            .icon {
                min-width: 4.875rem;
                min-height: 4.875rem;
                border: 0.063rem solid $fontLightGray;
                display: grid;
                place-items: center;
                position: relative;
                border-radius: 100%;
                z-index: 1;

                @media screen and (max-width: $sm) {
                    min-width: 4rem;
                    min-height: 4rem;
                }
                svg {
                    z-index: 2;
                }
                &::before {
                    position: absolute;
                    background-color: #f5f5f5;
                    width: 80%;
                    height: 80%;
                    content: '';
                    border-radius: 100%;
                    z-index: 1;
                }
            }
            .text_container {
                margin-left: 0.938rem;
                margin-top: 1rem;
                .equipment_name {
                    font-size: 1.375rem;
                    color: $fontTitle;
                    font-weight: bold;
                    @include text-wrap;
                }
                .equipment_grade {
                    text-transform: uppercase;
                    color: $fontParagraph;
                    font-size: 0.875rem;
                    font-weight: 500;
                }
                &.mobile {
                    display: flex;
                    align-items: center;
                }
            }

            .mobile_counter_card_info {
                .equipment_name {
                    font-size: 1rem;
                    @media screen and (max-width: $sm) {
                        font-size: 0.8rem;
                    }
                }
                .equipment_grade {
                    font-size: 0.87rem;
                    @media screen and (max-width: $sm) {
                        font-size: 0.6rem;
                    }
                }

                .sidebar_card_counter {
                    margin-left: 1rem;
                    margin-top: 0.3rem;
                }
            }
        }
        .card_actions_container {
            display: flex;
            align-items: baseline;
            gap: 2rem;

            @media screen and (max-width: $xl) {
                align-self: self-start;
                margin-top: 1.1rem;
            }
            @media screen and (max-width: $sm) {
                margin-top: 0.8rem;
            }

            .delete_icon {
                width: 1rem;
                height: 1rem;
                cursor: pointer;

                svg {
                    fill: $fontParagraph;
                    width: 0.7rem;
                    height: 0.7rem;

                    @media screen and (max-width: $md) {
                        width: 0.7rem;
                        height: 0.7rem;
                    }
                }
            }
        }
    }

    .stock_error {
        border: 1px solid $statusError;
        position: relative;
        overflow: hidden;

        .error_icon {
            width: 1rem;
            position: absolute;
            height: 1rem;
            top: 0.8rem;
            left: 0.6rem;
            svg {
                width: 1rem;
                height: 1rem;
            }
        }

        .error_text {
            margin-left: 2rem;

            .error_title {
                font-size: 1rem;
                font-weight: bold;
                color: $statusError;
                margin-top: 0.625rem;
            }
            .error_description {
                font-size: 1rem;
                font-weight: 500;
                color: $fontParagraph;
                margin-top: 0.2rem;
            }
        }
    }
}
