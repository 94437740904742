@import '../../../styles/variables';

.sidebar_options {
    background-color: $fontLighterGray;
    padding: 1.375rem 1.188rem;
    border-radius: 0.625rem;
    min-height: 16rem;
    position: relative;

    .info_container {
        display: flex;
        align-items: baseline;
        gap: 0.4rem;
        margin-bottom: 2rem;

        .options_title {
            font-size: 1.375rem;
            color: $fontTitle;
            font-weight: 600;
        }

        .info_icon {
            @include icon-size(1rem);
            fill: $statusOk;
            transform: translateY(0.1rem);
        }
    }

    .options_list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        min-height: 2.5rem;
        position: relative;

        .option {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            gap: 1rem;
            @media screen and (max-width: $sm) {
                align-items: center;
            }

            .option_name {
                font-weight: 400;
            }

            .option_action {
                .add_option {
                    display: flex;
                    align-items: center;
                    gap: 0.2rem;
                    height: 2.188rem;
                    border-radius: 4.375rem;
                    border: 0.128rem solid $fontLightGray;
                    padding: 0 1.5rem;
                    font-weight: 500;
                    cursor: pointer;
                    min-width: 9rem;
                    color: $fontTitle;

                    @media (hover: hover) and (pointer: fine) {
                        &:hover {
                            background-color: $computed-secondary;
                            border: 0.128rem solid $computed-secondary;
                            color: $computed-primary-font;
                        }
                    }

                    &.active {
                        background-color: $computed-secondary;
                        border: 0.128rem solid $computed-secondary;
                        color: $computed-secondary-font;
                    }

                    .price {
                        font-weight: 700;
                    }
                }

                .free {
                    justify-content: center;
                    cursor: default;
                    pointer-events: none;
                }
            }
        }
    }

    .price_container {
        margin-top: 2rem;
        .price_row {
            padding: 0.5rem 0;
            color: $fontTitle;
            font-weight: bold;

            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1rem;
            flex-direction: row;
        }

        .underline {
            border-bottom: 0.063rem solid $fontLightGray;
        }
    }
}

.option_tooltip_with_icon {
    display: flex;
    gap: 0.5rem;

    .info_icon {
        @include icon-size(1rem);
        fill: $statusOk;
        transform: translateY(0.1rem);
    }

    .tooltip_description {
        display: flex;
        flex-direction: column;
    }
}
