@import '../../styles/variables';

@mixin text-wrap-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 35rem;

    @media screen and (max-width: $md) {
        width: 23rem;
    }
    @media screen and (max-width: $sm) {
        width: 90vw;
        margin-right: 0.5rem;
    }
}

@mixin base-margin-top {
    margin-top: 2rem;
}

.create_donation_sidebar {
    width: 40rem;
    z-index: 2;
    padding: 0rem 2rem 1rem 2rem;

    @media screen and (max-width: $md) {
        width: 28.688rem;
    }

    @media screen and (max-width: $sm) {
        width: 100vw;
        padding: 0rem 1rem 1rem 1rem;
    }
    .close_icon {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .icon_container {
            width: 2.063rem;
            height: 4.063rem;
            display: grid;
            place-content: center;
            cursor: pointer;
            svg {
                width: 1.563rem;
                height: 1.563rem;
                fill: $computed-primary;
            }
        }
    }

    .donation_sidebar_title {
        font-size: 3.188rem;
        color: $fontTitle;
        font-weight: 600;
        margin-top: 1rem;
        word-wrap: break-word;

        @media screen and (max-width: $xl) {
            margin-top: 0;
        }

        @media screen and (max-width: $md) {
            font-size: 2.188rem;
        }

        @media screen and (max-width: $sm) {
            font-size: 1.875rem;
        }

        &::after {
            content: ' ';
            margin-top: 2.5rem;
            border-bottom: 0.25rem solid $computed-primary;
            width: 8.75rem;
            height: 1px;
            display: block;
            position: absolute;
            @media screen and (max-width: $md) {
                margin-top: 2rem;
            }
        }
    }

    .donation_name_input {
        margin-top: 3.125rem;

        @media screen and (max-width: $sm) {
            margin-top: 2.125rem;
        }
    }

    .sidebar_donation_options_container {
        @include base-margin-top;
    }
    .sidebar_beneficiary_select_container {
        .sidebar_beneficiary_section_name {
            display: flex;
            align-items: center;
            gap: 1rem;
            font-size: 1.375rem;
            color: $fontTitle;
            font-weight: 600;
            @include base-margin-top;

            .beneficiary_icon {
                width: 2.375rem;
                height: 2.375rem;
                svg {
                    width: 2.375rem;
                    height: 2.375rem;
                    fill: $computed-primary;
                }
            }
        }

        &.client {
            @include base-margin-top;
        }
    }

    .sidebar_donation_buttons {
        .sidebar_btn {
            width: 100%;
            height: 3.875rem;
            margin-top: 1.375rem;
            font-size: 1.25rem;

            @media screen and (max-width: $sm) {
                height: 3rem;
                font-size: 1rem;
            }
        }
    }

    .beneficiary_short_data {
        color: $fontTitle;
        margin-left: 1.7rem;
        .info_row {
            margin: 0.3rem 0;
        }
    }

    .beneficiary_actions {
        margin-top: 1rem;
        margin-left: 1.7rem;
        text-decoration: underline;
        font-size: 0.875rem;
        color: $fontParagraph;
        display: flex;
        gap: 2rem;
        font-weight: 500;
        span {
            cursor: pointer;
        }
    }

    &.loading {
        position: relative;
    }

    .donation_status {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
        .donation_id {
            font-size: 0.8rem;
            color: $fontParagraph;
        }
    }

    .date {
        margin-top: 0.5rem;
        font-weight: 400;
        color: $fontTitle;
        font-size: 0.8rem;
    }
}
