@import '../../../styles/variables';

.container {
    .mobile_header {
        header {
            background-color: white;
            display: flex;
            justify-content: space-between;
            gap: 4rem;
            height: 5rem;
            align-items: center;
            padding-right: 2rem;

            .account_logo {
                margin-left: 1.87rem;
                width: 5.5rem;

                img {
                    width: 5.5rem;
                }
            }

            .icon_placeholder {
                svg {
                    width: 1.8rem;
                    height: 1.8rem;

                    path {
                        fill: $primaryMain;
                    }
                    line {
                        stroke: $primaryMain;
                    }
                }
            }
        }
    }

    .mobile {
        background-color: white;
        max-height: inherit;
        height: calc(var(--doc-height));

        .close_sidebar_container {
            display: flex;
            justify-content: flex-end;
            height: 6rem;
            gap: 4rem;
            align-items: center;
            padding-right: 2rem;

            svg {
                width: 1.8rem;
                height: 1.8rem;
                path {
                    fill: $primaryMain;
                }
                line {
                    stroke: $primaryMain;
                }

                @media screen and (max-width: $sm) {
                    width: 1.2rem;
                    height: 1.2rem;
                }
            }
        }
    }
}
