@import '../../styles/variables';

.maintains_page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .header {
        height: 4rem;
        background-color: $fontFormTitle;
        width: 100%;
    }
    .logo {
        height: 15rem;
        margin-top: 4rem;
        width: 25rem;
        margin-top: 4rem;

        @media screen and (max-width: $sm) {
            width: 17rem;
            height: 10rem;
            margin-top: 4rem;
        }
    }

    .message {
        font-size: 2rem;
        color: $fontTitle;

        @media screen and (max-width: $sm) {
            text-align: center;
        }
    }

    .sub_message {
        font-size: 1.5rem;
        color: $fontTitle;
        @media screen and (max-width: $sm) {
            text-align: center;
        }
    }

    .reload_btn {
        width: 15rem;
        height: 3rem;
        margin-top: 5rem;
    }
}
